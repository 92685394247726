import { API_PATH } from "../../commons/constants";
import fetchApi from "../../commons/fetch-api";

export const CHANGE_RELIABILITIES_ACTION = "USER/CHANGE_RELIABILITIES_ACTION";
export const CHANGE_LEVELDATA_ACTION = "USER/CHANGE_LEVELDATA_ACTION";
export const LIST_BANKING_PROVIDERS = "USER/LIST_BANKING_PROVIDERS";
export const LIST_CLOUD_CERTIFICATE_PROVIDERS =
  "USER/LIST_CLOUD_CERTIFICATE_PROVIDERS";
export const CHANGE_RELIABILITY_INFO = "USER/CHANGE_RELIABILITY_INFO";
export const VIEW_USERRELIABILITIES_BYLEVEL =
  "USER/VIEW_USERRELIABILITIES_BYLEVEL";
export const VIEW_USERRELIABILITIES_BYID = "USER/VIEW_USERRELIABILITIES_BYID";
export const MODAL_ACQUIREDRELIABILITIES_VISIBILITY =
  "MODAL_ACQUIREDRELIABILITIES_VISIBILITY";
export const MODAL_ACQUIREDRELIABILITY_VISIBILITY =
  "MODAL_ACQUIREDRELIABILITY_VISIBILITY";
export const MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY =
  "MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY";
export const MODAL_NEWLEVELNOTIFICATION_VISIBILITY =
  "MODAL_NEWLEVELNOTIFICATION_VISIBILITY";
export const FORM_DATA = "FORM_DATA";
export const IS_RELIABILITY_ACQUIRED = "IS_RELIABILITY_ACQUIRED";

export const retrieveBankingProviders = () => async (dispatch, state) => {
  var bankingProviders = await fetchApi(
    "/api/aquisicao-confiabilidade-bancos-credenciados",
    { method: "GET" }
  );
  dispatch(
    changeBankingProvidersData({
      ...state.bankingProviders,
      bankingProviders: bankingProviders
    })
  );
};

export const retrieveUserReliabilities = () => async (dispatch, state) => {
  var reliabilities = await fetchApi(API_PATH.endpoints.getReliabilities, {
    method: "GET"
  });

  let levelData = {
    ...state.levelData
  };

  var levelIds = [];
  var currentLevel;

  reliabilities
    .filter(l1 => l1.anyAcquired)
    .forEach(l2 => {
      levelIds.push(parseInt(l2.level.id));
      currentLevel = Math.max(...levelIds);
    });

  if (currentLevel > levelData.level && currentLevel > 1) {
    levelData.up = true;
  }

  const currentUserReliabilities = reliabilities.flatMap(level =>
    level.categories
      .filter(category => category.anyAcquired)
      .flatMap(category =>
        category.reliabilities
          .filter(reliability => reliability.acquired)
          .map(reliability => reliability.reliability.id)
      )
  );

  if (levelData?.up) {
    levelData.newReliability = currentUserReliabilities.filter(
      rel => !levelData?.userReliabilities?.includes(rel)
    )[0];
  }

  levelData.level = currentLevel;
  levelData.userReliabilities = currentUserReliabilities;

  dispatch(
    changeReliabilitiesData({
      ...state.reliabilities,
      reliabilities: reliabilities
    })
  );
  dispatch(
    changeLevelData({
      ...state.levelData,
      ...levelData
    })
  );
  if (levelData.up) {
    dispatch(setModalNewLevelNotificationVisibility(true));
  }
};

export const retrieveUserReliabilitiesByClient = (
  param_clientId,
  param_reliabilities,
  param_categories,
  param_levels,
  param_redirectUri,
  param_redirectUriState
) => async (dispatch, state) => {
  const formData = {
    param_clientId,
    param_reliabilities,
    param_categories,
    param_levels,
    param_redirectUri,
    param_redirectUriState
  };
  const reliabilities = await fetchApi(API_PATH.endpoints.getReliabilities, {
    method: "POST",
    body: JSON.stringify(formData)
  });

  if (reliabilities) {
    dispatch(
      changeReliabilitiesData({
        ...state.reliabilities,
        reliabilities: reliabilities.lstLevel,
        client: reliabilities.client,
        redirect: reliabilities.redirectAppClient,
        messageClient: reliabilities.messageClient,
        redirectUri: reliabilities.redirectUri,
        redirectUriState: reliabilities.redirectUriState,
        redirectMessage: reliabilities.redirectMessage
      })
    );
  }
};

export const retrieveCloudCertificateProviders = () => async (
  dispatch,
  state
) => {
  var cloudCertificateProviders = await fetchApi(
    "/api/aquisicao-confiabilidade-certificados-nuvem",
    { method: "GET" }
  );
  dispatch(
    changeCloudCertificateProvidersData({
      ...state.cloudCertificateProviders,
      cloudCertificateProviders: cloudCertificateProviders
    })
  );
};

export const changeCloudCertificateProvidersData = cloudCertificateProviders => ({
  type: LIST_CLOUD_CERTIFICATE_PROVIDERS,
  cloudCertificateProviders
});

export const changeBankingProvidersData = bankingProviders => ({
  type: LIST_BANKING_PROVIDERS,
  bankingProviders
});

export const changeReliabilitiesData = reliabilities => ({
  type: CHANGE_RELIABILITIES_ACTION,
  reliabilities
});

export const changeLevelData = levelData => ({
  type: CHANGE_LEVELDATA_ACTION,
  levelData
});

export const changeReliabilityInfo = reliability => ({
  type: CHANGE_RELIABILITY_INFO,
  reliability
});

export const viewUserReliabilitiesByLevel = levelId => ({
  type: VIEW_USERRELIABILITIES_BYLEVEL,
  levelId
});

export const viewUserReliabilitiesById = id => ({
  type: VIEW_USERRELIABILITIES_BYID,
  id
});

export const setModalAcquiredReliabilitiesVisibility = data => ({
  type: MODAL_ACQUIREDRELIABILITIES_VISIBILITY,
  data
});

export const setModalAcquiredReliabilityVisibility = data => ({
  type: MODAL_ACQUIREDRELIABILITY_VISIBILITY,
  data
});

export const setModalNewLevelNotificationVisibility = data => ({
  type: MODAL_NEWLEVELNOTIFICATION_VISIBILITY,
  data
});

export const setModalHowToObtainReliabilityVisibility = data => ({
  type: MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY,
  data
});

export const reliabilityAcquired = data => ({
  type: IS_RELIABILITY_ACQUIRED,
  data
});

export const changeFormData = data => ({
  type: FORM_DATA,
  data
});
