import {
  CHANGE_RELIABILITIES_ACTION,
  CHANGE_RELIABILITY_INFO,
  VIEW_USERRELIABILITIES_BYLEVEL,
  MODAL_ACQUIREDRELIABILITIES_VISIBILITY,
  MODAL_NEWLEVELNOTIFICATION_VISIBILITY,
  MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY,
  FORM_DATA,
  IS_RELIABILITY_ACQUIRED,
  VIEW_USERRELIABILITIES_BYID,
  MODAL_ACQUIREDRELIABILITY_VISIBILITY,
  LIST_BANKING_PROVIDERS,
  LIST_CLOUD_CERTIFICATE_PROVIDERS,
  CHANGE_LEVELDATA_ACTION
} from "./actions";

export const reliabilityAcquiredReducer = (state, action) => {
  switch (action.type) {
    case IS_RELIABILITY_ACQUIRED:
      return { ...state, is_reliability_acquired: action.data };
    default:
      return state;
  }
};

export const reliabilitiesReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_RELIABILITIES_ACTION:
      return { ...state, ...action.reliabilities };
    default:
      return state;
  }
};

export const levelDataReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LEVELDATA_ACTION:
      return { ...state, ...action.levelData };
    default:
      return state;
  }
};

export const bankingProvidersReducer = (state, action) => {
  switch (action.type) {
    case LIST_BANKING_PROVIDERS:
      return { ...state, ...action.bankingProviders };
    default:
      return state;
  }
};

export const cloudCertificateProvidersReducer = (state, action) => {
  switch (action.type) {
    case LIST_CLOUD_CERTIFICATE_PROVIDERS:
      return { ...state, ...action.cloudCertificateProviders };
    default:
      return state;
  }
};

export const modalReliabilitiesReducer = (state, action) => {
  switch (action.type) {
    case MODAL_ACQUIREDRELIABILITIES_VISIBILITY:
      return { ...state, acquired_reliabilities: action.data };
    case MODAL_ACQUIREDRELIABILITY_VISIBILITY:
      return { ...state, acquired_reliability: action.data };
    case MODAL_HOWTOOBTAINRELIABILITY_VISIBILITY:
      return { ...state, obtain_reliability: action.data };
    default:
      return state;
  }
};

export const modalNewLevelNofitificationReducer = (state, action) => {
  switch (action.type) {
    case MODAL_NEWLEVELNOTIFICATION_VISIBILITY:
      return { ...state, new_level_notification: action.data };
    default:
      return state;
  }
};

export const reliabilityInfoReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_RELIABILITY_INFO:
      return { ...state, info: action.reliability };
    default:
      return state;
  }
};

export const userReliabilitiesByLevelReducer = (state, action) => {
  switch (action.type) {
    case VIEW_USERRELIABILITIES_BYLEVEL:
      return { ...state, level: action.levelId };
    case VIEW_USERRELIABILITIES_BYID:
      return { ...state, reliability: action.id };
    default:
      return state;
  }
};

export const changeFormDataReducer = (state, action) => {
  switch (action.type) {
    case FORM_DATA:
      return { ...state, ...action.data };
    default:
      return state;
  }
};
