import React, { useEffect, useState } from "react";
import { useAppState } from "../AppContext";
import { useHistory } from "react-router-dom";
import { changeInfoTela } from "../layout/actions";
import Card from "../../commons/Card";
import {
  retrieveCloudCertificateProviders,
  changeReliabilityInfo,
  retrieveUserReliabilities,
  setModalAcquiredReliabilityVisibility,
  setModalHowToObtainReliabilityVisibility,
  viewUserReliabilitiesById
} from "./actions";
import ModalAcquiredReliability from "./ModalAcquiredReliability";
import Modal from "react-modal";

Modal.setAppElement("#root");

const componentDidMount = dispatch => () => {
  window.scrollTo(0, 0);
  dispatch(
    changeInfoTela({
      title: "Como aumentar o nível da sua conta",
      breadcrumbs: [
        { label: "Aumentar nível da conta", link: "/" },
        { label: "Como aumentar o nível da sua conta" }
      ]
    })
  );
  dispatch(retrieveUserReliabilities());
  dispatch(retrieveCloudCertificateProviders());
};

export default function CertificadosNuvem() {
  const [state, dispatch] = useAppState();

  let [{ cloudCertificateProviders, reliabilities }] = useAppState();
  let lvls = reliabilities.reliabilities;
  let allMyReliabilities = [];

  useEffect(componentDidMount(dispatch), []);

  let apiBaseUrl =
    "https://" +
    window.location.hostname +
    "/api/aquisicao-confiabilidade-certificados-nuvem";
  let history = useHistory();

  const viewUserReliability = rel => {
    dispatch(setModalAcquiredReliabilityVisibility(true));
    dispatch(viewUserReliabilitiesById({ rel }));
  };

  const obtainReliabilityFromProvider = provider => {
    let relId =
      cloudCertificateProviders.cloudCertificateProviders.providerToReliability[
        provider
      ];
    let rel = lvls[1].categories
      .filter(c => c.category.id == "204")[0]
      .reliabilities.filter(r => r.reliability.id === relId)[0];

    if (allMyReliabilities.includes(rel.reliability.id)) {
      viewUserReliability(rel);
    } else {
      openHowToObtainReliabilityModal(rel.reliability);
    }
  };

  const openHowToObtainReliabilityModal = reliability => {
    dispatch(setModalHowToObtainReliabilityVisibility(true));
    dispatch(changeReliabilityInfo({ reliability }));
  };

  const [modalAcessoSeguroIsOpen, setModalAcessoSeguroIsOpen] = useState(false);

  return (
    <>
      {/* Obtendo as minhas reliabilities */}
      {lvls &&
        lvls
          .filter(l1 => l1.anyAcquired)
          .map(l2 => {
            for (let i = 0; i < l2.categories.length; i++) {
              l2.categories[i].reliabilities
                .filter(r1 => r1.acquired)
                .map(r2 => allMyReliabilities.push(r2.reliability.id));
            }
          })}

      <span className="page-title">Certificados em Nuvem</span>
      <span className="page-description">
        Você pode aumentar o nível da sua conta gov.br para ouro utilizando
        certificado digital em nuvem emitido por uma Autoridade Certificadora
        (AC), em conformidade com as regras estabelecidas pelo Comitê Gestor da
        ICP-Brasil.
      </span>
      {allMyReliabilities.includes("801") && (
        <>
          <p>
            Seus dados já foram validados utilizando o certificado digital. Se
            precisar atualizar o seu certificado digital em nuvem, escolha seu
            provedor.
          </p>
        </>
      )}

      <div className="home-cards">
        {cloudCertificateProviders &&
          cloudCertificateProviders?.cloudCertificateProviders?.providers?.map(
            provider => (
              <>
                <Card
                  id={provider.id}
                  key={provider.id}
                  css="card-bank"
                  onClick={() =>
                    window.location.assign(provider.authorizationUrl)
                  }
                  imgSrc={provider.iconUrl}
                  description={provider.title}
                />
              </>
            )
          )}
      </div>

      <ModalAcquiredReliability />
    </>
  );
}
