export const API_URL = `${window.location.protocol}//${window.location.hostname}`;
export const API_PATH = {
  endpoints: {
    start: `${API_URL}/api/login-openid`,
    logout: `${API_URL}/api/logout`,
    getUserInfo: `${API_URL}/api/userinfo`,
    getUserInfoPhoto: `${API_URL}/api/userinfo/picture`,
    getReliabilities: `${API_URL}/api/reliabilities`,
    postCreateKbaQuestions: `${API_URL}/api/:accountId/reliabilities/person-info-kba/questions`,
    postCreateSocialSecurityToken: `${API_URL}/api/:accountId/reliabilities/social-security/token`,
    getSocialSecurityRedirectEndpoint: `${API_URL}/api/social-security-kba-redirect-endpoint?token=:tokenValue`,
    getSocialSecurityRedirectEndpointErrors: `${API_URL}/api/social-security-kba-redirect-endpoint/errors`,
    postCreateBancoBrasilAuthorizationProcess: `${API_URL}/api/:accountId/reliabilities/banco-brasil-internet-banking/oauth2-process`,
    getBancoBrasilRedirectEndpoint: `${API_URL}/api/oauth2-redirect-endpoint?code=:codeValue&state=:stateValue`,
    getBancoBrasilRedirectEndpointErrors: `${API_URL}/api/oauth2-redirect-endpoint/errors`,
    postCreateFacialBiometricsQrCode: `${API_URL}/api/:accountId/reliabilities/facial-biometrics/:reliabilityId/qrcode`,
    putValidateFacialBiometricsQrCode: `${API_URL}/api/:accountId/reliabilities/facial-biometrics/qrcode/:biovalidPin`,
    putValidateDriverLicenseDate: `${API_URL}/api/:accountId/reliabilities/facial-biometrics`,
    putReliability: `${API_URL}/api/:accountId/reliabilities/:reliabilityId`
    //putReliabilityCertificate: `https://certificado.confiabilidades.acesso.gov.br/api/:accountId/reliabilities/:reliabilityId`,
  }
};

export const INITIAL_STATE = {
  aux_store: {},
  logged_user: {
    id: "",
    name: "",
    email: "",
    emailVerified: "",
    phoneNumber: "",
    phoneNumberVerified: "",
    amr: "",
    cnpj: "",
    photoBase64: ""
  },
  layout: {
    loading: false,
    title: "",
    breadcrumbs: [],
    message: {}
  },
  reliabilities: {},
  reliabilityInfo: {},
  userReliabilitiesByLevel: {},
  formData: {
    questions: [],
    formData: {},
    biovalid: {
      biovalidValidated: false,
      reliabilityAcquired: false,
      biometricsValidationSource: undefined
    }
  },
  form_alter_cadastro: {
    cpf: "",
    name: "",
    email: "",
    phoneNumber: "",
    emailVerified: false,
    token: "",
    otp: "",
    phoneNumberVerified: false,
    fileName: "-",
    errors: [],
    tmpAddress: {},
    addresses: [],
    address: {},
    removeAddress: {
      tipo: ""
    }
  },
  modal_reliability_visibility: {
    acquired_reliability: false,
    acquired_reliabilities: false,
    obtain_reliability: false
  },
  modal_new_level_notification_visibility: {
    new_level_notification: false
  },
  levelData: {
    up: false
  },
  is_reliability_acquired: false,
  form_alter_senha: {
    cpf: "",
    atual: "",
    nova: "",
    repeticao: "",
    errors: []
  },
  modal_visibility: {
    email_open: false,
    phone_open: false,
    nome_open: false
  },
  modal_address_visibility: {
    address_open: false,
    confirm_remove_address_open: false
  },
  dropdown_menu: {
    avatar: false,
    servico: false
  }
};

export const providerToReliabilityId = providerId => {
  const reliabilityFrom = {
    "banco-do-brasil": 602,
    banrisul: 603,
    bradesco: 604,
    "banco-de-brasilia": 606,
    "caixa-economica": 605,
    sicoob: 607,
    santander: 608,
    agibank: 609,
    itau: 610,
    sicredi: 624,
    mercantil: 618,
    picpay: 625,
    nubank: 626,
    btgpactual: 627,
    neoid: 801,
    safeid: 801,
    birdid: 801,
    remoteid: 801,
    serasa: 801
  };

  return reliabilityFrom[providerId];
};
