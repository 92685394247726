import { setDependenciesFunctions } from "./commons/fetch-api";
import { AppStateStore } from "./components/AppContext";
import { loggedUserReducer } from "./commons/logged-user/reducer";
import {
  reliabilitiesReducer,
  levelDataReducer,
  bankingProvidersReducer,
  modalReliabilitiesReducer,
  modalNewLevelNofitificationReducer,
  reliabilityInfoReducer,
  userReliabilitiesByLevelReducer,
  reliabilityAcquiredReducer,
  changeFormDataReducer,
  cloudCertificateProvidersReducer
} from "./components/home/reducer";

import { layoutReducer } from "./components/layout/reducer";
import { auxStoreReducer } from "./commons/auxiliary-store/reducer";
import { changeLoading, changeMessageDelay } from "./components/layout/actions";
import { INITIAL_STATE } from "./commons/constants";

const Configurations = {
  configFetchApi: () => {
    setDependenciesFunctions({
      changeLoadingState: isLoading => {
        AppStateStore.dispatch(changeLoading(isLoading));
      },
      changeMessageDelay: message => {
        AppStateStore.dispatch(changeMessageDelay(message));
      }
    });
  },
  reducers: (state, action) => {
    if (typeof action === "function") {
      // Chamada assíncrona das actions que são functions.
      setTimeout(() => {
        action(AppStateStore.dispatch, state);
      }, 0);
      return state;
    }

    const newState = {
      aux_store: auxStoreReducer(state.aux_store, action),
      logged_user: loggedUserReducer(state.logged_user, action),
      reliabilities: reliabilitiesReducer(state.reliabilities, action),
      levelData: levelDataReducer(state.levelData, action),
      bankingProviders: bankingProvidersReducer(state.bankingProviders, action),
      reliabilityInfo: reliabilityInfoReducer(state.reliabilityInfo, action),
      cloudCertificateProviders: cloudCertificateProvidersReducer(
        state.cloudCertificateProviders,
        action
      ),
      userReliabilitiesByLevel: userReliabilitiesByLevelReducer(
        state.userReliabilitiesByLevel,
        action
      ),
      formData: changeFormDataReducer(state.formData, action),
      layout: layoutReducer(state.layout, action),
      modal_reliability_visibility: modalReliabilitiesReducer(
        state.modal_reliability_visibility,
        action
      ),
      modal_new_level_notification_visibility: modalNewLevelNofitificationReducer(
        state.modal_new_level_notification_visibility,
        action
      ),
      is_reliability_acquired: reliabilityAcquiredReducer(
        state.is_reliability_acquired,
        action
      )
    };

    if (process.env.NODE_ENV !== "production") {
      // console.log('%c before: ' + JSON.stringify(state), 'color: gray')
      // console.log('%c action: ' + JSON.stringify(action), 'color: blue')
      // console.log('%c after: ' + JSON.stringify(newState), 'color: green')
      // console.log('%c after: ' + JSON.stringify(newState.formData), 'color: green')
    }

    return newState;
  },
  initialState: INITIAL_STATE
};

export default Configurations;
